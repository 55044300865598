import supportedLanguages from './supported-languages';
import defaultGlobalClientConfig, {
  IGlobalClientConfig,
} from '../../../default/config/GlobalClientConfig/GlobalClientConfig';
import {
  AuthenticationFlowTypes,
  FeaturedEventsCarouselType,
} from '../../../../config/Enums/App';
import Routes from '../../../../config/Routes/Routes';
import { MsisdnRequiredness } from '../../../../config/Enums/MsisdnRequiredness';

const GlobalClientConfig: IGlobalClientConfig = {
  ...defaultGlobalClientConfig,
  siteTitle: 'Ooredoo EZ | Ember',
  clientName: 'Ooredoo EZ',
  copyrightName: 'Swarmio Media',
  clientOrganizationSiteUrl: 'https://swarmio.media/',
  marketingSiteUrl: 'https://ooredooez.emberhub.gg',
  accountSetupRoute: Routes.subscribe,
  socialLinks: {
    facebook: 'https://www.facebook.com/OoredooEZ/',
    twitter: 'https://twitter.com/OoredooEZ',
    instagram: 'https://www.instagram.com/OoredooEZ/',
  },
  nav: {
    orientation: 'vertical',
  },
  currency: {
    showOnlyPoints: true,
  },
  numberOfEventsToShowOnHomePage: 2,
  numberOfServersToShowOnHomePage: 2,
  authenticationFlowType: AuthenticationFlowTypes.Carousel,
  featuredEventsCarouselType: FeaturedEventsCarouselType.Centered,
  streams: {
    headerType: 'carousel',
  },
  internationalization: {
    defaultLng: 'en',
    supportedLngs: supportedLanguages,
  },
  eventDiscordLink: 'https://discord.gg/xKvzQ3ww6b',
  msisdn: {
    format: 'TN',
    requiredness: MsisdnRequiredness.OPTIONAL,
  },
  countryCode: 788,
  storeBanner: (storePartnertId: string | undefined) =>
    `https://store-assets.arenaesports.ae/store-banners/headers/${storePartnertId}/header-image.png`,
  colors: {
    ...defaultGlobalClientConfig.colors,
    darkGrey: '#a5a5a5',
    lightGrey: '#c2c6d5',
    black: '#0f0f0f',
    white: '#f4f9ff',
    primary: '#ef3e2d',
    secondary: 'black',
    danger: 'red',
    success: '#80ff80',
    warning: '#ffcb50',
    dark: '#1a1a1a',
    light: '#c2c6d5',
  },
};

export default GlobalClientConfig;
